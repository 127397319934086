.forgot-password {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login_wrapper {
  max-width: 455px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #232323;
  border-radius: 15px;
  padding: 50px;
}
.logo_img {
  /* border-bottom: 1px solid #dee2e6; */
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.login_form {
  font-family: Open Sans, sans-serif;
  color: #35424a;
}
.login_form .input_icon {
  position: relative;
}
.login_form .form-control {
  padding: 10px;
  padding-left: 50px;
  border-color: #232323 !important;
  font-family: Helvetica, sans-serif;
}
.input_icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* color: #1180f1;
      font-size: 16px; */
}
a.forget_link {
  display: block;
  text-align: right;
  color: #006ce5 !important;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
}
.login_form .form-check {
  color: #5a5e61;
}
.btn-submit {
  color: #fff !important;
  background-color: #037aff !important;
  border-color: #037aff !important;
  font-family: Helvetica, sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  width: 100% !important;
  border-radius: 16px !important;
  margin-top: 30px !important;
  margin-bottom: 50px !important;
}
.login-text {
  font-size: 14px;
}
.login-contaoner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login_wrapper {
  max-width: 455px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #232323;
  border-radius: 15px;
  padding: 50px;
}
.logo_img {
  /* border-bottom: 1px solid #dee2e6; */
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.login_form {
  font-family: Open Sans, sans-serif;
  color: #35424a;
}
.login_form .input_icon {
  position: relative;
}
.login_form .form-control {
  padding: 10px;
  padding-left: 50px;
  border-color: #232323 !important;
  font-family: Helvetica, sans-serif;
}
.input_icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* color: #1180f1;
      font-size: 16px; */
}
a.forget_link {
  display: block;
  text-align: right;
  color: #006ce5 !important;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
}
.login_form .form-check {
  color: #5a5e61;
}
.btn-submit {
  color: #fff;
  background-color: #037aff;
  border-color: #037aff;
  font-family: Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  border-radius: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.login-text {
  font-size: 14px;
}
